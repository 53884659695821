import * as React from 'react'


import IndexLayout from '../layouts'
import HeaderDesktop from "../components/HeaderDesktop";
import ContactDiv from "../components/contact/Contact";
import Helmet from "react-helmet";

const PageTwo = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'Kontakt'}
      meta={[
        { name: 'description', content: 'Kontakt Vilhelm7 Telefon: +49 173 2070 173 E-Mail: info@vilhelm7.de Apartments Hotel Zimmer Berlin Übernachtung Zentrum' },
        { name: 'keywords', content: 'Kontakt, Vilhelm7, Telefon, Fax, E-Mail'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />
    <HeaderDesktop></HeaderDesktop>
    <ContactDiv/>
  </IndexLayout>
)

export default PageTwo
