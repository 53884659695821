import * as React from 'react'
import styled from '@emotion/styled'
import { IoMdCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { FormattedMessage } from 'react-intl';


const StyledDiv = styled.div`

  width: 100%;
text-align:center;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:2em;
font-family: "Theano Didot", Regular;
font-size: 1.22em;
letter-spacing: 1px;



`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 180%;
text-align: right;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;



`;


const StyledPhoneIcon = styled(IoMdCall)`
width:26px;
height:26px;
transition: all .7s ease;
margin-right:5px;
opacity:0;
display:none;


`



const StyledMailIcon = styled(IoMdMail)`
width:26px;
height:26px;
margin-top:2px;
transition: all .7s ease;
margin-right:5px;
opacity:0;
display:none;



`

const ABold = styled.a`
font-family: "Theano Didot", Regular;
font-weight:400;
line-height: 1.5em;
color:rgb(68, 68, 68) ;
font-size:1.6em;
opacity:1;
transition:all .7s ease;

:hover{
opacity:0.7;}

:hover {
${StyledMailIcon}{
//display:block;
//opacity:0.7;
}

${StyledPhoneIcon}{
//display:block;
//opacity:0.7;
}
}



`;

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-weight:bold;
line-height: 1.5em;
color:rgb(68, 68, 68);
margin-bottom:0px;
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size:1.5em;
line-height: 1.4em;
color:rgb(68, 68, 68);
margin-bottom:0px;

`;

const PItalic = styled.p`
font-family: "Theano Didot", Regular;
font-size:1.6em;
font-style:italic;
line-height: 1.4em;
color:rgb(68, 68, 68);
margin-bottom:0px;

`;

const Div = styled.div`
margin-bottom:10px;
width:100%;
display:;flex;
justify-content:center;
align-items:center;
`





interface ContactDivProps {
  className?: string
}

const ContactDiv: React.FC<ContactDivProps> = ({ }) => (
  <StyledDiv>
    <Div>

        <ABold href={'mailto:info@vilhelm7.de'}>  <StyledMailIcon/> info@vilhelm7.de</ABold>
    </Div>

    <br/>
    <P > Vilhelm7 </P>
    <PItalic > Berlin Residences </PItalic>
    <P> Wilhelmstraße 7</P>
    <P>10963 Berlin</P>
  </StyledDiv>

)

export default ContactDiv
